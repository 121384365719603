import { RouteObject } from "react-router-dom";
import { Home } from "../pages/Home";
import { Tools } from "../pages/Tools";
import { Triggers } from "../pages/Triggers";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/tools",
    element: <Tools />,
  },
  {
    path: "/triggers",
    element: <Triggers />,
  },
];
