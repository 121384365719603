import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

type PageHeadingProps = HeadingProps;

const PageHeading: React.FC<PageHeadingProps> = ({ children }) => {
  return (
    <>
      <Heading as="h1" size="2xl">
        {children}
      </Heading>
    </>
  );
};

export { PageHeading };
