import React from "react"

type TriggersProps = {};

const Triggers: React.FC<TriggersProps> = () => {
  return (
    <>
      
    </>
  )
}

export { Triggers };