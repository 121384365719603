import React from "react"

type ToolsProps = {};

const Tools: React.FC<ToolsProps> = () => {
  return (
    <>
      Tools
    </>
  )
}

export { Tools };