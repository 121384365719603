import React from "react";
import { useRoutes } from "react-router-dom";
import { routes } from "./config";

type AppRouterProps = {};

const AppRouter: React.FC<AppRouterProps> = () => {
  const appRoutes = useRoutes(routes);

  return <>{appRoutes}</>;
};

export { AppRouter };
