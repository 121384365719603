import React from "react";
import { PageHeading } from "components/PageHeading";

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <PageHeading>Home</PageHeading>
    </>
  );
};

export { Home };
